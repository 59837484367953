<template>
  <div>
    <b-modal id="changeStatus" size="lg" title="Change Status" style="direction: rtl" centered hide-footer hide-header-close>
      <b-row class="d-flex flex-wrap" >
        <b-col v-for="(status, key) in allStatus" :key="key" class="col-4 text-black text-center mb-3 cursor-pointer" >
           <span
               :class="[{ 'shadow bg-primary text-white' : isSelected(status.value)},'d-inline-block w-100 p-1 custom-rounded  primary-color ']" @click="selectedStatusId = status.value">
            <i  :class="[{ 'las la-check-square' : isSelected(status.value)}]"></i> {{status.text}}
          </span>
          <span  class="d-block w-100 h-10 bg-white"></span>
          <span  :class="`status--${status.value} d-block w-100 h-10`">
          </span>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center mt-2">
        <b-button v-if='saveButton' class="btn btn-primary float-right" variant="primary" disabled>
          <spinner-loading text = 'Loading' />
        </b-button>
        <b-button variant="primary" v-else class="w-50" @click="saveEditStatus">Save</b-button>
      </div>
    </b-modal>

    <dashboard-page-title :showAddBtn="false" :showFilter="true" :showSearch="true" :showExportation="true">الشركات</dashboard-page-title>

    <main-table :fields="fields" :baseURL="VUE_APP_TRANSPORTATION_LINK" :list_url="'companies'" :reloadData="reloadData"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_TRANSPORTATION_LINK } from '@/config/constant'
import companyServices from '@/modules/transportation/services/transportation'
export default {
  name: 'companies',
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_TRANSPORTATION_LINK,
      reloadData: true,
      allStatus: [
        {
          value: 'pending',
          text: 'جارى الطلب'
        },
        {
          value: 'refused',
          text: 'تم الرفض'
        },
        {
          value: 'accepted',
          text: 'تم القبول'
        }
      ],
      saveButton: false,
      selectedStatusId: '',
      companyId: null,
      fields: [
        { label: 'شعار الشركة', key: 'logo', type: 'image', class: 'text-right' },
        { label: 'اسم الشركة', key: 'name', type: 'name', class: 'text-right' },
        { label: 'عدد السائقين', key: 'name', class: 'text-right' },
        { label: 'عدد الشاحنات', key: 'numberOfVehicles', type: 'text', class: 'text-right' },
        { label: 'عدد الرحلات', key: 'numberOfTrips', type: 'text', class: 'text-right' },
        { label: ' اجمالى الأرباح', key: 'profits', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              text: 'تغيير الحالة',
              icon: 'las la-sync',
              color: 'primary',
              actionName: 'showPopupChangeStatusOrder',
              actionParams: ['id']
            }
          ]
        }
      ]
    }
  },
  methods: {
    saveEditStatus () {
      this.saveButton = true
      companyServices.changeCompanyStatus(this.companyId, this.selectedStatusId).then(res => {
        core.showSnackbar('success', 'تم تغير حالة الطلب بنجاح')
        this.saveButton = false
        this.$bvModal.hide('changeStatus')
      })
    },
    isSelected (value) {
      return value === this.selectedStatusId
    },
    showPopupChangeStatusOrder (data) {
      this.companyId = data.id
      this.$bvModal.show('changeStatus')
    }
  },
  created () {
    this.$root.$on('showPopupChangeStatusOrder', this.showPopupChangeStatusOrder)
  }
}
</script>
